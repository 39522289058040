section .date {
  display: flex;
  justify-content: start;
  gap: 1.3rem;
  align-items: center;
  margin-bottom: 1rem;
}

/* date_hari */
section .date_hari {
  text-align: center;
}
h3,
span,
h4,
h2 {
  margin: 1px;
  padding: 0;
}

section .date_hari h2 {
  font-size: 2rem;
}

/* date_pemisah */
section .date_pemisah {
  width: 1px;
  height: 40px;
  background-color: rgb(151, 148, 148);
}

/* date_waktu */
section .date_waktu {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.date_waktu .date_waktu_icon {
  border-radius: 5px;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #bbf7d0;
}

.date_waktu_icon svg {
  width: 2rem;
  height: 2rem;
  fill: var(--primary-color);
}

/* thumbnile */
.thumbnile {
  position: relative;
  width: 100%;
  height: 150px;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
}

.thumbnile::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 991px) {
  .card-admin {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 576px) {
  .card-admin {
    display: none;
  }
}
