:root {
  --primary-linear-gradient: linear-gradient(to right, #2f7f6c, #22c55e);
  --hover-linear-gradient: linear-gradient(to right, #22c55e, #2f7f6c);
  --primary-color: #22c55e;
  --button-background: #a566ff;
  --button-hover-background: #b38bff;
}

.loginContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}

.inputField {
  border-radius: 10px;
  padding: 0.6rem 1.5rem;
  padding-left: 3rem;
  border: 1px solid #ccc;
  font-size: 1rem;
  width: 100%;
  background-color: #f7f7f7;
}

.inputIcon {
  position: absolute;
  top: 65%;
  left: 1rem;
  transform: translateY(-50%);
  font-size: 1.25rem;
  color: #aaa;
}

.button {
  background: var(--primary-linear-gradient);
  color: #fff;
  padding: 0.75rem;
  border: none;
  border-radius: 10px;
  transition: background 0.3s ease, transform 0.3s ease;
  cursor: pointer;
  font-weight: bold;
}

.button:hover {
  transform: translateY(-2px);
  color: #f7f7f7;
  background: var(--hover-linear-gradient);
}

.button:active {
  transform: translateY(0);
}

.alert {
  margin-bottom: 1rem;
}

.link {
  color: #888;
  text-decoration: none;
  font-size: 0.875rem;
}

.link:hover {
  text-decoration: underline;
}

.signUpLink {
  color: var(--primary-color);
}
